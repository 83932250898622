import * as React from 'react'
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import Games from './pages/Games';
import Rowbot from './pages/Rowbot';


export type AppRouteInterfaceProps = {
    index?: boolean,
    requireAuth?: boolean
    element: JSX.Element,
    path?: string,
}



const AppRoutes = [
    {
        index: true,
        element: <Rowbot />,
        requireAuth: false,
        path: '/rowbot'
    },
    {
        index: true,
        element: <Games />,
        requireAuth: false,
        path: '/games'
    },
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
