import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';


interface NavMenuProps {
    children: React.ReactNode;
}

export class Layout extends Component<NavMenuProps> {
    static displayName = Layout.name;

    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
            message: undefined,
            isReady: false,
            authenticated: false
        };
    }

    render() {
        console.log("props children: " + this.props.children);
        return (
            <div>
                <NavMenu />
                <Container>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
