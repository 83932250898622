export const ApplicationName = 'Rowbot';

interface QueryParameterName{
    ReturnUrl: string;
    Message: string;
}

export const QueryParameterNames: QueryParameterName = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};


interface LogoutAction{
    LogoutCallback: string,
    Logout: string,
    LoggedOut: string
};

export const LogoutActions: LogoutAction = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export interface LoginAction{
    Login: string,
    LoginCallback: string,
    LoginFailed: string,
    Profile: string,
    Register: string
};

export const LoginActions: LoginAction = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register'
};

const prefix = '/authentication';


interface ApplicationPath{
  DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: string,
    ApiAuthorizationPrefix: string,
    Login: string,
    LoginFailed: string,
    LoginCallback: string,
    Register: string,
    Profile: string,
    LogOut: string,
    LoggedOut: string,
    LogOutCallback: string,
    IdentityRegisterPath: string,
    IdentityManagePath: string,
};

export const ApplicationPaths: ApplicationPath = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Register: `${prefix}/${LoginActions.Register}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: 'Identity/Account/Register',
  IdentityManagePath: 'Identity/Account/Manage'
};
