﻿import * as React from 'react';
import Game from '../game/Game';
import styles from './Games.module.css';

const Games = () => {
    return (
        <div className={styles.contentContainer}>
            <div className={styles.neonBorder}>                
                <Game />
            </div>
        </div>
    );
}

export default Games;
