import { useEffect, useState } from "react";
import authService from '../components/api-authorization/AuthorizeService';
import styles from './Rowbot.module.css';
import * as React from 'react';


interface Activity {
    id: number,
    name: string,
    distance: number,
    time: string
}

interface ActivityModel{
    user: string
    activities: Activity[]
}

const Rowbot = () => {

    const [data, setData] = useState<ActivityModel>({
        user: 'test',
        activities: [{
            id: 1,
            name: 'tester',
            distance: 100,
            time: '00:02:45'
           }]
    });

    useEffect(() => {
        console.log("in the useEffect");
        const api = async () => {
            const token = await authService.getAccessToken();
            const response = await fetch('activity', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            var currentData = data ?? "no data"
            console.log("Pre call data: " + JSON.stringify(data));
            const responseData = await response.json();
            console.log("Post call responsedata" + JSON.stringify(responseData));
            setData(responseData);
            console.log("post call data: " + JSON.stringify(data));
        }        
        api();
        
    }, []);

    const getTable = () => {
        if (data) {
            console.log("there IS data");
            return (
                <div className={styles.neonBorder }>
                <table className={`table table-striped {}' + ${styles.neonTable }`} aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Distance (m)</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody className={styles.neonTableBody }>
                        {data!.activities.map(activity =>
                            <tr key={activity.id}>
                                <td>{activity.name}</td>
                                <td>{activity.distance}</td>
                                <td>{activity.time}</td>
                            </tr>
                        )}
                    </tbody>
                    </table>
                </div>
            );
        }
        else {
            console.log("no data");
        }
    }
    
    return (        
        <div className={styles.contentContainer }>
            {getTable() }
        </div>
    )
}

export default Rowbot