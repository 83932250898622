import React, { Component } from 'react';
import styles from './App.module.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Rowbot from './pages/Rowbot';
import SideNav from './components/Sidenav';
import { NavMenu } from './components/NavMenu';
import { Layout } from './components/Layout';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';

export default class App extends Component {
    static sideNavItems = [
        { label: 'Home', link: '/home' },
        { label: 'Rowbot', link: '/rowbot' }
    ];


    render() {
        return (
            <>
                <div className={styles.appcontainer}>
                    <div className={styles.image}>
                        <div className={styles.top}>
                            <div className={styles.sky}></div>
                        </div>
                            <div className={styles.text}>
                                <div className={styles.skyleechTitle}>SkyLeech Studio</div>
                                <div className={styles.skyleechText}>Showing the world to you</div>
                            </div>
                        
                        
                    </div>
                    <div className={styles.test}>
                        <Layout>
                            <Routes>
                                {AppRoutes.map((route, index) => {
                                    const { element, requireAuth, ...rest } = route;
                                    return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                                })}
                            </Routes>
                        </Layout>
                    </div>
                </div>
            </>
        )
    }

}