import React, { ReactElement } from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'


interface AuthorizeRouteProps {
    path?: string;
    element: ReactElement;
}

interface AuthorizeRouteState {
    ready: boolean;
    authenticated: boolean;
}

export default class AuthorizeRoute extends Component<AuthorizeRouteProps, AuthorizeRouteState> {
    private _subscription?: ReturnType<typeof authService['subscribe']>;

    
    constructor(props: AuthorizeRouteProps) {
    super(props);
    
    this.state = {
      ready: false,
      authenticated: false
    };
  }

  componentDidMount(): void {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount(): void {
    authService.unsubscribe(this._subscription);
  }

    render(): ReactElement | null{
    const { ready, authenticated } = this.state;
    var link = document.createElement("a");
    link.href = this.props.path ?? '';
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else {
      const { element } = this.props;
      return authenticated ? element : <Navigate replace to={redirectUrl} />;
    }
  }

    async populateAuthenticationState(): Promise<void> {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

    async authenticationChanged(): Promise<void> {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
