﻿import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import Card from './Card';
import styles from './game.module.css';
import { shuffleArray } from './Utility'; 


const TOTAL_PAIRS = 12;

const Game: React.FC = () => {
    const [cards, setCards] = useState<Array<{
        flipCount: number;
        isMatched: boolean; value: number, isFlipped: boolean
    }>>([]);
    const [turns, setTurns] = useState(0);
    const [flippedCards, setFlippedCards] = useState<number[]>([]);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);

    const initializeGame = useCallback(() => {
        const newCards = [];
        for (let i = 1; i <= TOTAL_PAIRS; i++) {
            newCards.push({ value: i, isFlipped: false, isMatched: false, flipCount: 0 });
            newCards.push({ value: i, isFlipped: false, isMatched: false, flipCount: 0 });
        }
        setCards(shuffleArray(newCards));
        setTurns(0);
        setIsGameOver(false);
    }, []);


    // Initialize the game on first render
    useEffect(() => {
        initializeGame();
    }, [initializeGame]);

    const handleCardClick = useCallback((index: number) => {
        // Ignore clicks if the game is waiting or the card is already flipped
        if (isWaiting || cards[index].isFlipped) {
            return;
        }

        // Flip the card and increment the flip count
        const newCards = cards.map((card, i) => {
            if (i === index) {
                return { ...card, isFlipped: true, flipCount: card.flipCount + 1 };
            }
            return card;
        });
        setCards(newCards);

        // Add the card to the flipped cards
        const newFlippedCards = [...flippedCards, index];
        setFlippedCards(newFlippedCards);

        // If two cards are flipped, check for a match
        if (newFlippedCards.length === 2) {
            setTurns(prevTurns => prevTurns + 1);
            if (newCards[newFlippedCards[0]].value === newCards[newFlippedCards[1]].value) {
                // It's a match! Create a new array with the matched cards
                const updatedCards = newCards.map((card, i) => {
                    if (i === newFlippedCards[0] || i === newFlippedCards[1]) {
                        return { ...card, isMatched: true };
                    }
                    return card;
                });
                setCards(updatedCards);
                setIsGameOver(updatedCards.every(card => card.isMatched));
            } else {
                // Not a match. Flip the cards back over after a delay
                setIsWaiting(true);
                setTimeout(() => {
                    // Create a new array with the updated cards
                    const updatedCards = newCards.map((card, i) => {
                        if (i === newFlippedCards[0] || i === newFlippedCards[1]) {
                            return { ...card, isFlipped: false };
                        }
                        return card;
                    });
                    setCards(updatedCards);
                    setIsWaiting(false);
                }, 1000);
            }

            // Reset the flipped cards
            setFlippedCards([]);
        }
    }, [cards, flippedCards, isWaiting]);


    const mostFlippedCard = cards.reduce((prevCard, currCard) => {
        return currCard.flipCount > prevCard.flipCount ? currCard : prevCard;
    }, cards[0]);

    return (
        <div className={styles['game-container']}>
            <h1 className={styles.gameTitle}>Match 'em up!</h1>
            <button className={styles.restartButton} onClick={initializeGame}>Restart Game</button>
            <p className={styles.playerInfoText}>Turns: {turns}</p>
            {isGameOver ? (
                <div>
                    <h2 className={styles.playerInfoText}>You win!</h2>
                    <p className={styles.playerInfoText}>The most flipped card was flipped {mostFlippedCard.flipCount} times!</p>
                    <img src={`./imgs/card${mostFlippedCard.value}.png`} alt="Most flipped card" />
                </div>
            ) : (
                <div className={styles['card-grid']}>
                    {cards.map((card, index) => (
                        <Card key={index} value={card.value} isFlipped={card.isFlipped}
                            isMatched={card.isMatched} flipCount={0}
                            onCardClick={() => handleCardClick(index)} />
                    ))}
                </div>)}
        </div>
    );
};

export default Game;


