﻿import * as React from 'react';
import styles from './card.module.css';
interface CardProps {
  value: number;
    isFlipped: boolean;
    isMatched: boolean;
    flipCount: number;
  onCardClick: () => void;
}

const Card: React.FC<CardProps> = ({ value, isFlipped, isMatched, onCardClick }) => {
    return (
        <div className={`${styles.card} ${isFlipped ? styles.isFlipped : ''} ${isMatched ? styles.isMatched : ''}`} onClick={onCardClick}>
            <div className={styles.cardFront}>
                <img className={styles.cardImage} src='./imgs/cardback04.png' alt="card back" />
            </div>
            <div className={styles.cardBack}>                
                <img className={styles.cardImage} src={`./imgs/card${value}.png`} alt="card face" />
            </div>
        </div>
    );
};

export default Card;


