import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import styles from './NavMenu.module.css';


interface NavMenuProps {

}

interface NavMenuState {
    collapsed: boolean
}

export class NavMenu extends Component<NavMenuProps> {
    static displayName = NavMenu.name;

    constructor(props: any) {
        super(props);

      /*  this.toggleNavbar = this.toggleNavbar.bind(this);*/
        this.state = {
            collapsed: true
        };
    }

    //toggleNavbar() {
    //    this.setState({
    //        collapsed: !this.state.collapsed
    //    });
    //    console.log("navbar collapsed state is: " + this.state.collapsed )
    //}

    render() {
        return (
            <div className={styles.sidebar}>
                   {/* <Navbar className={ styles.container}>*/}
                        {/*<NavbarBrand tag={Link} to="/">Rowbot</NavbarBrand>*/}
                        {/*<NavbarToggler onClick={this.toggleNavbar} className={styles.togglerButton } />*/}
                        {/*<Collapse  isOpen={!this.state.collapsed} navbar >*/}
                         <div className={styles.container}>
                                <NavItem >
                                    <NavLink to={'/rowbot'} className={({ isActive }) => (isActive ? styles.active : 'inactive')}>Rowbot</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to={'/fetchdata'} className={({ isActive }) => (isActive ? styles.active : 'inactive')}>Fetch Data</NavLink>
                                </NavItem>
                    <NavItem>
                                <NavLink to={'/games'} className={({ isActive }) => (isActive ? styles.active : 'inactive')}>Games</NavLink>
                                </NavItem>
                                {/*<LoginMenu>*/}
                                {/*</LoginMenu>*/}
                                </div>
                    {/*    </Collapse>*/}
                 {/*   </Navbar>*/}
            </div>
        );
    }
}
